export default class LandingView {
  constructor() {
    this.init();
  }

  init() {
    const countryToggler = document.getElementsByClassName('js-country-changer')[0];
    const countryList = document.getElementsByClassName('js-country-list')[0];

    if (countryToggler && countryList) {
      countryToggler.addEventListener('click', event => {
        event.preventDefault();
        countryList.style.display = countryList.style.display === 'block' ? 'none' : 'block';
      });
    }
  }
}
